import { SplitText } from 'gsap/all';

const duration = 1;

class TimelineEffectsProcessor {
  selector;

  timeline;

  constructor(selector, timeline) {
    this.selector = selector;
    this.timeline = timeline;
  }

  timelineMap = {
    maskInUp: (transition) =>
      this.timeline.fromTo(
        new SplitText(this.selector(transition.selector), {
          type: 'lines, words',
          linesClass: 'line',
          wordsClass: 'word',
        }).words,
        { y: '100%' },
        { y: '0', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    exitTop: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { y: 0 },
        { y: '-100vh', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    maskOutUp: (transition) =>
      this.timeline.fromTo(
        new SplitText(this.selector(transition.selector), {
          type: 'lines, words',
          linesClass: 'line',
          wordsClass: 'word',
        }).words,
        { y: '0' },
        { y: '-100%', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    enterBottom: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { y: '100vh' },
        { y: 0, duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),

    dotDeActivate: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { backgroundColor: '#8fff00' },
        {
          backgroundColor: 'transparent',
          duration: transition.duration ? transition.duration : duration,
        },
        transition.sequence
      ),

    dotActivate: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { backgroundColor: 'transparent' },
        {
          backgroundColor: '#8fff00',
          duration: transition.duration ? transition.duration : duration,
        },
        transition.sequence
      ),

    enterLeft: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { x: '-100vw' },
        { x: '0', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),

    maskOutDown: (transition) =>
      this.timeline.fromTo(
        new SplitText(this.selector(transition.selector), {
          type: 'lines, words',
          linesClass: 'line',
          wordsClass: 'word',
        }).words,
        { y: '0' },
        { y: '100%', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    opacityIn: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { opacity: 0 },
        { opacity: 1, duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    scale: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { scale: transition.from },
        {
          scale: transition.to,
          duration: transition.duration ? transition.duration : duration,
        },
        transition.sequence
      ),
    moveX: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { x: transition.from },
        { x: transition.to, duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    moveY: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { y: transition.from },
        { y: transition.to, duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    maskInDown: (transition) =>
      this.timeline.fromTo(
        new SplitText(this.selector(transition.selector), {
          type: 'lines, words',
          linesClass: 'line',
          wordsClass: 'word',
        }).words,
        { y: '-100%' },
        { y: '0', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    exitLeft: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { x: '0' },
        { x: '-100vw', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    enterRight: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { x: '100vw' },
        { x: '0', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    morphSvg: (transition) =>
      this.timeline.to(
        this.selector(transition.selector),
        {
          morphSVG: transition.morphDestination,
          duration: transition.duration ? transition.duration : duration,
        },
        transition.sequence
      ),
    opacityOut: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { opacity: '100%' },
        { opacity: '0', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    borderIn: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { borderRadius: '100%', width: 0, height: 0 },
        {
          borderRadius: 0,
          width: '100%',
          height: '100%',
          duration: transition.duration ? transition.duration : duration,
        },
        transition.sequence
      ),
    exitRight: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { x: '0' },
        { x: '100vw', duration: transition.duration ? transition.duration : duration },
        transition.sequence
      ),
    hide: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { opacity: '100%', pointerEvents: 'all', display: 'flex' },
        { opacity: '0', pointerEvents: 'none', display: 'none', duration: 0.1 },
        transition.sequence
      ),
    show: (transition) =>
      this.timeline.fromTo(
        this.selector(transition.selector),
        { opacity: '0', pointerEvents: 'none' },
        { opacity: '100%', pointerEvents: 'all', duration: 0.1 },
        transition.sequence
      ),
    addLabel: (transition) => this.timeline.addLabel(transition.label, '>'),
  };

  addEffectsToTimeLine(transitions) {
    transitions.map((transition) => { // eslint-disable-line
      if (this.timelineMap[transition.effect]) this.timelineMap[transition.effect](transition);
      else throw new Error('Effect not handled');
    });
  }
}

export default TimelineEffectsProcessor;
