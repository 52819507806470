import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollToPlugin, MorphSVGPlugin, ScrollSmoother, ScrollTrigger } from 'gsap/all';
import { isIOS } from 'react-device-detect';

import 'styles/main.scss';
import './TrucleanPage.scss';
import Seo from 'components/common/Seo';
import TimelineEffectsProcessor from 'utils/addEffectsToTimeline';
import TrucleanBlock from './TrucleanBlock';

const TrucleanPage = ({
  data: {
    trucleanPage: {
      scrollTransitions,
      landingTransitions,
      blocks,
      scrollTransitionsMobile,
      parallaxBackgrounds,
      desktopParallaxHeight,
      mobileParallaxHeight,
      desktopSmoothScrollFactor,
      mobileSmoothScrollFactor,
      mobileEasingFunction,
      desktopEasingFunction,
      navClickDuration,
      seoMetaKeywords,
      seoMetaDescription,
      seoNoIndex,
      seoMetaTitle,
      seoCanonicalUrl,
      seoExternalHreflangs,
      seoImage,
      ogPageType,
      link,
    },
    brandSettings,
    siteSettings,
  },
}) => {
  const el = useRef<HTMLDivElement>();
  const landingTimeline = useRef<gsap.core.Timeline>();
  const scrollTimeline = useRef<gsap.core.Timeline>();
  const scrollSmoother = useRef<ScrollSmoother>();
  const parsedScrollTransitions = JSON.parse(scrollTransitions);
  const parsedScrollTransitionsMobile = JSON.parse(scrollTransitionsMobile);
  const parsedLandingTransitions = JSON.parse(landingTransitions);

  useEffect(() => {
    const selector = gsap.utils.selector(el);
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin, MorphSVGPlugin);

    landingTimeline.current = gsap.timeline({ paused: true });
    new TimelineEffectsProcessor(selector, landingTimeline.current).addEffectsToTimeLine(
      parsedLandingTransitions
    );

    scrollSmoother.current = ScrollSmoother.create({
      smooth: desktopSmoothScrollFactor,
      effects: true,
      smoothTouch: mobileSmoothScrollFactor,
      normalizeScroll: !isIOS,
      ignoreMobileResize: true,
      wrapper: '#gatsby-focus-wrapper',
      content: '#smooth-content',
    });

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': () => {
        scrollTimeline.current = gsap.timeline({
          scrollTrigger: {
            trigger: '.scroll-screen',
            start: 'top top',
            end: desktopParallaxHeight,
            pin: true,
            scrub: true,
          },
          defaults: { ease: desktopEasingFunction },
        });
        new TimelineEffectsProcessor(selector, scrollTimeline.current).addEffectsToTimeLine(
          parsedScrollTransitions
        );
      },
      // mobile
      '(max-width: 799px)': () => {
        scrollTimeline.current = gsap.timeline({
          scrollTrigger: {
            trigger: '.scroll-screen',
            start: 'top top',
            end: mobileParallaxHeight,
            pin: true,
            scrub: true,
          },
          defaults: { ease: mobileEasingFunction },
        });
        new TimelineEffectsProcessor(selector, scrollTimeline.current).addEffectsToTimeLine(
          parsedScrollTransitionsMobile
        );
      },
    });

    const navClickHandler = (e) => {
      const percent =
        scrollTimeline.current.labels[e.target.id] / scrollTimeline.current.totalDuration();
      const scrollPos =
        scrollTimeline.current.scrollTrigger.start +
        (scrollTimeline.current.scrollTrigger.end - scrollTimeline.current.scrollTrigger.start) *
          percent;
      gsap.to(window, { duration: navClickDuration, scrollTo: scrollPos });
    };

    gsap.utils.toArray(selector('.navigation__dot, .nav-link')).forEach((navDotTarget) => {
      navDotTarget.addEventListener('click', navClickHandler);
    });

    const handleResize = () => {
      gsap.to('.scroll-section', { height: innerHeight }); // eslint-disable-line
    };
    gsap.to('.truclean-page', { opacity: 1, duration: 0.5 });
    landingTimeline.current?.play();

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(() => {
        if (document.getElementsByTagName('body')[0].getAttribute('style') == null) {
          ScrollTrigger.refresh();
        }
      });
    });

    const target = document.getElementsByTagName('body')[0];
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });

    return () => {
      window.removeEventListener('resize', handleResize);
      gsap.utils.toArray(selector('.navigation__dot, .nav-link')).forEach((navDotTarget) => {
        navDotTarget.removeEventListener('click', navClickHandler);
      });
    };
  }, []);

  return (
    <div className="truclean-page" id="smooth-wrapper" ref={el}>
      <Seo
        siteSettings={siteSettings}
        brandSettings={brandSettings}
        seoNoIndex={seoNoIndex}
        title={seoMetaTitle}
        description={seoMetaDescription}
        keywords={seoMetaKeywords}
        canonicalUrl={seoCanonicalUrl}
        externalHreflangs={seoExternalHreflangs}
        ogImage={seoImage}
        url={link}
        ogPageType={ogPageType}
      />

      <div id="smooth-content">
        {parallaxBackgrounds.map(
          ({ properties: { className, speed, top, left, image } }, index) => (
            <img
              key={`background-${index}`}
              data-speed={speed}
              style={{ top, left }}
              className={className}
              src={image.base64}
              srcSet={image.srcSet}
              alt={image.altText}
            />
          )
        )}
        <div className="scroll-screen">
          {blocks.map((block, index) => (
            <TrucleanBlock key={`row-${index}`} {...block} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query ($lang: String, $link: String!) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    trucleanPage(link: { eq: $link }, lang: { eq: $lang }) {
      seoMetaKeywords
      seoMetaDescription
      seoNoIndex
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      ogPageType
      scrollTransitions
      scrollTransitionsMobile
      landingTransitions
      link
      mobileParallaxHeight
      desktopParallaxHeight
      mobileSmoothScrollFactor
      desktopSmoothScrollFactor
      mobileEasingFunction
      desktopEasingFunction
      navClickDuration
      parallaxBackgrounds {
        structure
        properties {
          image {
            altText
            fallbackUrl
            url
            srcSet
            base64
          }
          className
          top
          left
          speed
        }
      }
      blocks {
        ...block
        properties {
          blocks {
            ...block
            properties {
              blocks {
                ...block
                properties {
                  blocks {
                    ...block
                    properties {
                      blocks {
                        ...block
                        properties {
                          blocks {
                            ...block
                            properties {
                              blocks {
                                ...block
                                properties {
                                  blocks {
                                    ...block
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    brandSettings {
      brandName
      brandLogo {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      brandLogoSchema
      youtubeUrl
      facebookUrl
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
  }

  fragment block on TBlock {
    structure
    properties {
      text
      className
      rowID
      autoPlay
      repeat
      youtubeLink
      image {
        altText
        url
        fallbackUrl
        srcSet
        base64
      }
      trucleanVideo {
        altText
        fallbackUrl
        url
      }
      link {
        url
      }
    }
  }
`;

export default TrucleanPage;
