import { Link } from 'gatsby';
import React, { FC } from 'react';
import { ITrucleanProps } from './models';

const TrucleanBlock: FC<ITrucleanProps> = (
  {
    structure,
    properties: {
      rowID,
      autoPlay,
      className,
      youtubeLink,
      trucleanVideo,
      repeat,
      image,
      text,
      blocks,
      link,
    },
  },
  key
) => {
  switch (structure) {
    case 'Truclean Video':
      const [playing, setPlaying] = React.useState(autoPlay); // eslint-disable-line
      if (youtubeLink)
        return (
          <div className={`video-container ${className}`}>
            <iframe
              title="video"
              srcDoc={`
        <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
            }
        </style>
        <a
            href='https://www.youtube.com/embed/${youtubeLink}?autoplay=1'
            class='full'
        >
            <img
                src='https://img.youtube.com/vi/${youtubeLink}/maxresdefault.jpg'
                class='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    `}
              frameBorder="0"
            />
          </div>
        );

      return (
        <div className={`video-container ${className}`}>
          <video
            preload="auto"
            onClick={autoPlay ? () => {} : () => setPlaying(!playing)}
            onPlay={autoPlay ? () => {} : () => setPlaying(true)}
            onPause={autoPlay ? () => {} : () => setPlaying(false)}
            autoPlay={autoPlay}
            playsInline={autoPlay}
            muted={autoPlay}
            controls={!autoPlay}
            loop={repeat}
            className={className}
          >
            <source src={trucleanVideo.url} type="video/mp4" />
            <track src="" kind="captions" srcLang="en" label="english_captions" />
          </video>
          {!playing && !autoPlay ? (
            <img className="video-container__play-button" src={image.url} alt="video play button" />
          ) : null}
        </div>
      );

    case 'Truclean Image':
      return link ? (
        <Link to={link[0].url} className={`${className}`}>
          <img
            className={`${className} truclean-image`}
            src={image.base64}
            srcSet={image.srcSet}
            alt={image.altText}
          />
        </Link>
      ) : (
        <img
          className={`${className} truclean-image`}
          src={image.base64}
          srcSet={image.srcSet}
          alt={image.altText}
        />
      );
    case 'Truclean Block':
      return (
        <div id={rowID} className={className}>
          {blocks
            ? blocks.map((childBlock, index) => (
                <TrucleanBlock key={`${key}-${index}`} {...childBlock} />
              ))
            : null}
        </div>
      );

    case 'Text Block':
      // Text Block and Truclean SVG might have different implementations in the future
      // for now, they are identical
      // code duplication is here because eslint fails it otherwise
      return <div className={className} dangerouslySetInnerHTML={{ __html: text }} />;
    case 'Truclean SVG':
      return <div className={className} dangerouslySetInnerHTML={{ __html: text }} />;

    default:
      return <>{structure}</>;
  }
};
export default TrucleanBlock;
